import type {PageModel} from '@smart/website-page-model';
import * as React from 'react';
import {useFeatureServices} from './use-feature-services';

export function useNavigationScrolling(pageModel: PageModel): void {
  const {historyService} = useFeatureServices();
  const {history} = historyService;
  React.useLayoutEffect(() => {
    if (location.hash) {
      const anchorId = location.hash.slice(1);
      setTimeout(() => {
        const anchorElement = document.getElementById(anchorId);
        if (anchorElement) {
          anchorElement.scrollIntoView({behavior: `smooth`});
        } else {
          window.scrollTo({top: 0});
        }
      }, 0);
    } else if (history.action !== `POP`) {
      window.scrollTo({top: 0});
    }
  }, [pageModel]);
}
